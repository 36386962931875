import { Route, Routes, useLocation } from 'react-router';
import routes from 'routes/index.routes';
import { AppWrapper, HeaderContainer, ViewWrapper } from 'styled';
import { FC, Suspense } from 'react';
import Layout from 'layout';
import Footer from 'components/footer';
import NavBar from 'components/navBar';
require('assets/css/styles.css');

const App: FC = () => {
  const location = useLocation();
  const renderRoutes: () => JSX.Element[] = () =>
    routes.map((parentRoutes: any, i: number) => (
      <Route key={i} path={parentRoutes.path} element={parentRoutes.component} />
    ));
  return (
    <AppWrapper>
      {location?.pathname !== '/termsandconditions' && (
        <HeaderContainer>
          <NavBar />
        </HeaderContainer>
      )}
      <ViewWrapper>
        <Layout>
          <Suspense fallback={<h1>Loading...</h1>}>
            <Routes>{renderRoutes()}</Routes>
          </Suspense>
        </Layout>
      </ViewWrapper>
      {location?.pathname !== '/termsandconditions' && (
        <footer>
          <Footer />
        </footer>
      )}
    </AppWrapper>
  );
};

export default App;
