import { themeColors, device } from 'assets/theme/style';
import styled from 'styled-components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ListItem from "@mui/material/ListItem";
import { fontSizes } from 'assets/theme/typography';

export const NavigationMobileContainer = styled.div`
  display: none;
  @media ${device.mobileOnly} {
    display: flex;
  }
`;

export const NavigationMenuIconContainer = styled.button`
  color: ${themeColors.body};
  background: none;
  border: none;
  cursor: pointer;
`;

export const ProfileListItem = styled(ListItem)`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const SwipeableDrawerContainer = styled(SwipeableDrawer)`
  &.MuiDrawer-root{
    z-index: 999999;
  }

  .MuiPaper-root{
    width: 70%;
    min-width: 200px;
  }

`;

export const NavBarItems = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  list-style-type: none;

  a {
      font-size: ${fontSizes.regular};
      font-weight: 400;
      color: ${themeColors.grey};
      text-decoration: none;
    }

    a:hover, .selected{
      color: ${themeColors.green};
    }  
`;

export const NavBarItem = styled.li``;

export const NavBarLoginItem = styled.a`
  background-color: ${themeColors.lightBlack};
  color: ${themeColors.white}!important;
  padding: 6px 24px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

