import { ChangeEvent, MouseEvent, FC } from 'react';
import { TextInputContainer, TextInputLabel, TextInputMessage, TextInputStyled } from './styled';

export interface TextInputProps {
  defaultValue?: string;
  message?: string;
  errorMessage?: string;
  changeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  register?: any;
  label?: string;
  type?: 'number';
  placeholder?: string;
  multiline?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  endIcon?: any;
}

const TextInput: FC<TextInputProps> = ({
  defaultValue,
  message,
  errorMessage,
  register,
  label,
  type,
  placeholder,
  disabled,
  changeHandler,
  onClick,
  endIcon,
  multiline
}) => (
  <TextInputContainer>
    {label && <TextInputLabel>{label}</TextInputLabel>}

    <TextInputStyled
      placeholder={placeholder || ''}
      value={defaultValue}
      type={type}
      disabled={disabled}
      onClick={onClick}
      onChange={changeHandler}
      inputProps={register}
      InputProps={endIcon}
      multiline={multiline}
    />

    {(message || errorMessage) && (
      <TextInputMessage errorMessage={errorMessage || ''}>
        {message || errorMessage}
      </TextInputMessage>
    )}
  </TextInputContainer>
);

export default TextInput;
