import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { typography } from 'assets/theme/typography';
import { device, themeColors } from 'assets/theme/style';

export const ButtonStyled = styled.button<ButtonProps>`
    display: flex;
    width: ${({ width }) => width || '100%'};
    border: none;
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => padding || '18px 35px'};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
    background-color: ${({ backgroundColor, disabled }) =>
        disabled ? themeColors.grey : backgroundColor || themeColors.primary};
    color: ${({ textColor }) => textColor || themeColors.secondary};
    ${typography.bodySemiBold};
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: ${({ borderRight }) => (borderRight ? '12px' : '0px')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    @media only screen and ${device.tabletLandscape} {
      padding: ${({ padding }) => padding || '9px 24px'};
    }

    @media only screen and ${device.tabletPortrait} {
      padding: ${({ padding }) => padding || '9px 24px'};
    }

    @media only screen and ${device.desktop1024X768} {
      padding: ${({ padding }) => padding || '9px 24px'};
    }
`;
