import { FC, ReactNode } from 'react';
import { SecondaryButtonStyled } from './styled';
import { ButtonProps } from 'utils/interfaces/button/button.interface';

export interface SecondaryButtonProps extends ButtonProps {
  children?: ReactNode;
  testingTagLocation?: string;
}

const SecondaryButton: FC<SecondaryButtonProps> = ({
  text = '',
  clickHandler = (_e?: any) => {},
  textColor,
  borderColor,
  backgroundColor,
  disabled = false,
  children,
  padding,
  gap,
  minWidth,
  height,
  type,
  width
}) => (
  <SecondaryButtonStyled
    onClick={(e) => clickHandler(e)}
    textColor={textColor}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    disabled={disabled}
    padding={padding}
    gap={gap}
    minWidth={minWidth}
    height={height}
    type={type}
    width={width}
  >
    {children || text}
  </SecondaryButtonStyled>
);

export default SecondaryButton;
