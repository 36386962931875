import { device, size, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const IntegrationWrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(70% - 100px);
  background-color: ${themeColors.lightBlack};

  @media ${device.mobileOnly} {
    height: calc(100% - 100px);
    padding: 50px 0;
  }
  @media only screen and ${device.tabletPortrait} {
    height: calc(100% - 100px);
    padding: 50px 0;
  }
`;

export const IntegrationContainer = styled.div`
  display:flex;
  width: 100%;
  padding: 50px 80px;
  justify-content: center;
  align-items: center;
  max-width: ${size.desktop};
  margin: 0 auto;

  @media ${device.mobileOnly} {
    padding: 20px;
    flex-direction: column-reverse;
    height: 100%;
  }
  @media only screen and ${device.tabletLandscape} {
    padding: 0 40px;
  }
  @media only screen and ${device.tabletPortrait} {
    padding: 0 40px;
    flex-direction: column-reverse;
    height: 100%;
  }
  @media only screen and ${device.desktop1024X768} {
    padding: 0 40px;
  }
`;

export const IntegrationBlock60 = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${device.mobileOnly} {
    width: 100%;
    justify-content: center;
    gap: 15px;
  }
  @media only screen and ${device.tabletPortrait} {
    width: 100%;
    justify-content: center;
    gap: 15px;
  }

`;

export const IntegrationBlock40 = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;

  @media ${device.mobileOnly} {
    width: 100%;
    justify-content: center;

    img {
      width: 90%;
      margin-bottom: 30px;
    }
  }
  @media only screen and ${device.tabletLandscape} {
    img {
      width: 90%;
      margin-bottom: 30px;
    }
  }
  @media only screen and ${device.tabletPortrait} {
    width: 100%;
    justify-content: center;

    img {
      width: 60%;
      margin-bottom: 30px;
    }
  }
  @media only screen and ${device.desktop1024X768} {
    img {
      width: 90%;
      margin-bottom: 30px;
    }
  }
`;

export const IntegrationBlockCaptionRegular = styled.h2`
  margin: 0;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  color: ${themeColors.white};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.small};
  }
  @media only screen and ${device.desktop1024X768} {
    font-size: ${fontSizes.small};
  }
`;

export const IntegrationBlockCaptionLarge = styled.h1`
  margin: 0;
  font-size: ${fontSizes.xxLarge};
  font-weight: ${fontWeights.bold};
  color: ${themeColors.yellow};
  line-height: 92px;

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media only screen and ${device.tabletLandscape} {
    font-size: ${fontSizes.xLarge};
    line-height: 52px;
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media only screen and ${device.desktop1024X768} {
    font-size: ${fontSizes.xLarge};
    line-height: 52px;
  }
`;

export const IntegrationBlockDescription = styled.div`
  margin: 0;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  color: ${themeColors.white};
  line-height: 44px;

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
    line-height: 32px;
  }
  @media only screen and ${device.tabletLandscape} {
    font-size: ${fontSizes.small};
    line-height: 32px;
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.small};
    line-height: 32px;
  }
`;

export const IntegrationBlockButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: 40px;

  @media ${device.mobileOnly} {
    margin-top: 20px;
  }
`;

export const IntegrationBlockButtonText = styled.p`
  margin: 0;
  ${typography.bodyMediumBold};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
`;


