/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-console
import { FC } from 'react';
import {
  HomepageBlock40,
  HomepageBlock60,
  HomepageBlockCaptionRegular,
  HomepageContainer,
  HomepageWrapper,
  HomepageBlockCaptionLarge,
  HomepageBlockDescription,
  HomepageBlockButtonContainer,
  HomepageBlockButtonText
} from './styled';
import { PrimaryButton, SecondaryButton } from 'components/common/buttons';
import HomepageImage from 'assets/images/homepage-image.png';
import { getStartedLink } from 'lib/constants/getStartedLink';
import { themeColors } from 'assets/theme/style';
import { HashLink } from 'react-router-hash-link';

const Homepage: FC = () => {
  return (
    <HomepageWrapper id="homepage">
      <HomepageContainer>
        <HomepageBlock40>
          <HomepageBlockCaptionRegular>CONNECT TO ONE, CONNECT TO MANY</HomepageBlockCaptionRegular>
          <HomepageBlockCaptionLarge>
            Easy Access To Your Customers Accounting Data
          </HomepageBlockCaptionLarge>
          <HomepageBlockDescription>
            Build your FinTech solution with our simple accounting integrations. Whether you lend,
            insure, or provide other financial services, our integrations will make it easier to
            access the data you need and to effectively assess risk.
          </HomepageBlockDescription>
          <HomepageBlockButtonContainer>
            <PrimaryButton clickHandler={() => getStartedLink()} width="fit-content">
              <HomepageBlockButtonText>Get Started</HomepageBlockButtonText>
            </PrimaryButton>
            <HashLink smooth to="#contactUs">
              <SecondaryButton
                textColor={themeColors.primary}
                borderColor={themeColors.primary}
                backgroundColor="transparent"
                width="fit-content"
              >
                <HomepageBlockButtonText>Book a Demo</HomepageBlockButtonText>
              </SecondaryButton>
            </HashLink>
          </HomepageBlockButtonContainer>
        </HomepageBlock40>
        <HomepageBlock60>
          {/* <Parallax rotate={[0, 360]} easing="easeInQuad"> */}
          <img src={HomepageImage} />
          {/* </Parallax> */}
        </HomepageBlock60>
      </HomepageContainer>
    </HomepageWrapper>
  );
};

export default Homepage;
