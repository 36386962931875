import { themeColors } from "./style";

export const fontWeights = {
    bold: 700,
    medium: 500,
    semiBold: 600,
    regular: 400
};

export const fontSizes = {
    xxsmall: '12px',
    xsmall: '14px',
    small: '16px',
    regular: '18px',
    medium: '20px',
    large: '32px',
    xLarge: '40px',
    xxLarge: '56px'
};

export const typography = {
  tableHeader: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.regular}; text-transform: uppercase; color: ${themeColors.body}`,
    bodyRegular: `font-size: ${fontSizes.regular}`,
    bodySmallSemiBold: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.semiBold}`,
    bodyMediumBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.medium}`,
    bodySemiBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}`,
    navLink: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}`,
    bodyBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.bold}`,
    link: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}; text-decoration: underline; cursor: pointer;`,
    captionRegular: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.regular}`,
    captionMedium: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.medium}`,
    captionBold: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.bold}`,
    cardTitle: `font-size: ${fontSizes.medium}; font-weight: ${fontWeights.bold}`
};
