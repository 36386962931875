import { device, size, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.mobileOnly} {
    padding-bottom: 50px;
  }
`;

export const ContactUsBlockContainer = styled.div`
  display:flex;
  width: 100%;
  padding: 0 80px;
  justify-content: center;
  align-items: center;
  max-width: ${size.desktop};
  margin: 0 auto;

  @media ${device.mobileOnly} {
    padding: 0 20px;
    flex-direction: column;
  }
  @media only screen and ${device.tabletLandscape} {
    padding: 0 40px;
  }
  @media only screen and ${device.tabletPortrait} {
    padding: 0 40px;
    flex-direction: column;
  }
  @media only screen and ${device.desktop1024X768} {
    padding: 0 40px;
  }
`;

export const ContactUsBlock30 = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${device.mobileOnly} {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;

    img {
      width: 70%;
    }
  }
  @media only screen and ${device.tabletPortrait} {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;

    img {
      width: 30%;
    }
  }
`;

export const ContactUsBlock70 = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-end;

  @media ${device.mobileOnly} {
    width: 100%;
    justify-content: center;
  }
  @media only screen and ${device.tabletPortrait} {
    width: 100%;
    justify-content: center;
  }
`;

export const ContactUsHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 56px;

  @media ${device.mobileOnly} {
    width: 100%;
    padding: 0 20px;
    gap: 15px;
  }
  @media only screen and ${device.tabletLandscape} {
    width: 60%;
  }
  @media only screen and ${device.tabletPortrait} {
    width: 70%;
    padding: 0 20px;
    gap: 15px;
  }
  @media only screen and ${device.desktop1024X768} {
    width: 60%;
  }
`;

export const ContactUsHeaderTitle = styled.h2`
  margin: 0;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  color: ${themeColors.green};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.small};
  }
`;

export const ContactUsHeaderCaption = styled.h1`
  margin: 0;
  font-size: ${fontSizes.xLarge};
  font-weight: ${fontWeights.bold};
  color: ${themeColors.body};
  line-height: 67px;

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media only screen and ${device.tabletLandscape} {
    font-size: ${fontSizes.xLarge};
    line-height: 52px;
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media only screen and ${device.desktop1024X768} {
    font-size: ${fontSizes.xLarge};
    line-height: 52px;
  }
`;

export const ContactUsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 40px;
  background: ${themeColors.white};
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 32px;
  margin-bottom: 100px;

  @media ${device.mobileOnly} {
    width: 100%;
    margin-bottom: 50px;
  }
  @media only screen and ${device.tabletPortrait} {
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const ContactUsFormCaption = styled.h3`
  margin: 0;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
  color: ${themeColors.body};
`;

export const ContactUsFormSubmitMessage = styled.p`
  margin: 0;
  font-size: ${fontSizes.regular};
  color: ${themeColors.success};
`;

export const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 27px;
`;

export const ContactUsFormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media ${device.mobileOnly} {
    flex-direction: column;
  }
`;

export const ContactUsFormButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 'auto';
  margin-top: 8px;
`;

export const ContactUsFormButtonText = styled.p`
  ${typography.bodySemiBold};
  align-items: center;
  display: flex;
  gap: 10px;

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
`;
