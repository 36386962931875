import { Navigate } from 'react-router';
import { IRoute } from 'utils/interfaces/route/route.interface';
import SinglePage from 'views/SinglePage';
import TermsAndConditions from 'views/TermsAndConditions';

const routes: IRoute[] = [
  { path: '/', name: 'SinglePage', component: <SinglePage /> },
  { path: '/termsandconditions', name: 'TermsAndConditions', component: <TermsAndConditions /> },
  {
    path: '*',
    name: 'Redirect',
    component: <Navigate to="/" />
  }
];

export default routes;
