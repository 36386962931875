import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { ButtonStyled } from 'components/common/buttons/styled';
import { themeColors } from 'assets/theme/style';

export const SecondaryButtonStyled = styled(ButtonStyled)<ButtonProps>`
  border: ${({ textButton, textColor }) =>
    textButton ? '' : `1px solid ${textColor || themeColors.white}`};
  :disabled {
  }
`;