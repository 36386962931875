import { FC } from 'react';
import {
  FooterBottomContainer,
  FooterBottomCopyright,
  FooterBottomText,
  FooterContainer,
  FooterG2FLogo,
  FooterNavigationContainer,
  FooterNavigationItem,
  FooterNavigationSocialMediaIcons,
  FooterNavigationWrapper,
  FooterWrapper
} from './styled';
import G2FFooterLogo from 'assets/logos/footer-g2f-logo.png';
import FacebookIcon from 'assets/images/facebookIcon.png';
import LinkedinIcon from 'assets/images/linkedinIcon.png';
import { navItems } from 'lib/navigationData';
import Divider from 'components/common/divider';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer: FC = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterG2FLogo>
          <img src={G2FFooterLogo} />
        </FooterG2FLogo>
        <FooterNavigationWrapper>
          <FooterNavigationContainer>
            {navItems.map((item, index) => (
              <FooterNavigationItem key={`${item.text}-${index}`}>
                <HashLink smooth to={`${item.hash}`}>
                  {item.text}
                </HashLink>
              </FooterNavigationItem>
            ))}
            <FooterNavigationItem>
              <Link to={window.spaSettings.partnerPortalDomain}>Login</Link>
            </FooterNavigationItem>
          </FooterNavigationContainer>
          <FooterNavigationSocialMediaIcons>
            {/* <img src={FacebookIcon} /> */}
            <Link to="https://www.linkedin.com/company/finecta/" target="_blank">
              <img src={LinkedinIcon} />
            </Link>
          </FooterNavigationSocialMediaIcons>
        </FooterNavigationWrapper>
        <Divider margin="10px 0" />
        <FooterBottomContainer>
          <NavLink to="#">Privacy and Cookie Policy</NavLink>
          <Link to="http://www.finecta.com" target="_blank">
            <FooterBottomText>G2F Connect is a service from Finecta Ltd</FooterBottomText>
          </Link>
          <FooterBottomCopyright>© 2023 Finecta Ltd. All rights reserved</FooterBottomCopyright>
        </FooterBottomContainer>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
