import { FC } from 'react';
import { DividerLine } from './styled';

export interface DividerProps {
  color?: string;
  margin?: string;
}

const Divider: FC<DividerProps> = ({ color, margin }) => (
  <DividerLine color={color} margin={margin} />
);

export default Divider;
