import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { ButtonStyled } from 'components/common/buttons/styled';

export const PrimaryButtonStyled = styled(ButtonStyled)<ButtonProps>`
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
`;
