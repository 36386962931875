import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import {
  NavigationMobileContainer,
  NavigationMenuIconContainer,
  SwipeableDrawerContainer,
  NavBarItem,
  NavBarItems,
  NavBarLoginItem
} from './styled';
import { navItems } from 'lib/navigationData';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const MobileNav: FC = () => {
  const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);

  const toggleDrawer: (open: boolean) => void = (open) => {
    setIsMobileMenu(open);
  };

  const scrollWidthOffset: (el: any) => void = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <NavigationMobileContainer>
      <NavigationMenuIconContainer onClick={() => toggleDrawer(true)}>
        <MenuIcon />
      </NavigationMenuIconContainer>

      <SwipeableDrawerContainer
        anchor="right"
        open={isMobileMenu}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <Box role="presentation">
          <NavBarItems>
            {navItems.map((item, index) => (
              <NavBarItem
                key={`${item.text}-${index}`}
                onClick={() => {
                  toggleDrawer(false);
                }}
              >
                <HashLink smooth to={`${item.hash}`} scroll={(el) => scrollWidthOffset(el)}>
                  {item.text}
                </HashLink>
              </NavBarItem>
            ))}
            <NavBarItem key={`7-login`}>
              <NavBarLoginItem to={window.spaSettings.partnerPortalDomain}>Login</NavBarLoginItem>
            </NavBarItem>
          </NavBarItems>
        </Box>
      </SwipeableDrawerContainer>
    </NavigationMobileContainer>
  );
};

export default MobileNav;
