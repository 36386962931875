/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import FreeTrial from 'views/FreeTrial';
import Homepage from 'views/Homepage';
import { ParallaxProvider } from 'react-scroll-parallax';
import IntegrationView from 'views/IntegrationView';
import ContactUsView from 'views/ContactUsView';
import SolutionsView from 'views/SolutionsView';
import HowItWorksView from 'views/HowItWorksView';

const SinglePage: FC = () => {
  return (
    <ParallaxProvider>
      <Homepage />
      <FreeTrial />
      <SolutionsView />
      <IntegrationView />
      <HowItWorksView />
      <ContactUsView />
    </ParallaxProvider>
  );
};

export default SinglePage;
