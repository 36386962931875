/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-console
import { FC } from 'react';
import {
  SolutionsContainer,
  SolutionsWrapper,
  SolutionsHeaderCaption,
  SolutionsHeaderTitle,
  SolutionsHeaderContainer,
  SolutionsDividerLeft,
  SolutionsDividerContainer,
  SolutionsDividerRight,
  SolutionsDividerMiddle,
  SolutionsEllipseIcon,
  SolutionsCardWrapper,
  SolutionsCardContainer,
  SolutionsCardTitle,
  SolutionsCardListContainer,
  SolutionsCardList,
  SolutionsCardLinkContainer,
  SolutionsCardIcon,
  SolutionsEllipseMiddleIcon
} from './styled';
import { Link } from 'react-router-dom';
import SolutionCardArrow from 'assets/images/arrow.png';
import LendingIcon from 'assets/images/solutions-lending-icon.png';
import AnalysisIcon from 'assets/images/solutions-analysis-icon.png';
import BankingIcon from 'assets/images/solutions-banking-icon.png';
import InsuranceIcon from 'assets/images/solutions-insurance-icon.png';
import { getStartedUrl } from 'lib/constants/getStartedLink';

const SolutionsView: FC = () => {
  return (
    <SolutionsWrapper id="solutions">
      <SolutionsContainer>
        <SolutionsHeaderContainer>
          <SolutionsHeaderTitle>USING G2F CONNECT</SolutionsHeaderTitle>
          <SolutionsHeaderCaption>How Are Customers Using G2F Connect?</SolutionsHeaderCaption>
        </SolutionsHeaderContainer>
        <SolutionsDividerContainer>
          <SolutionsDividerLeft>
            <SolutionsEllipseIcon style={{ left: '-2px' }}></SolutionsEllipseIcon>
          </SolutionsDividerLeft>
          <SolutionsDividerMiddle>
            <SolutionsEllipseMiddleIcon></SolutionsEllipseMiddleIcon>
          </SolutionsDividerMiddle>
          <SolutionsDividerRight>
            <SolutionsEllipseIcon style={{ right: '-2px' }}></SolutionsEllipseIcon>
          </SolutionsDividerRight>
        </SolutionsDividerContainer>
        <SolutionsCardWrapper>
          <SolutionsCardContainer>
            <SolutionsCardIcon>
              <img src={LendingIcon} />
            </SolutionsCardIcon>
            <SolutionsCardTitle>Lending</SolutionsCardTitle>
            <SolutionsCardListContainer>
              <SolutionsCardList>Receivables and Payables financing</SolutionsCardList>
              <SolutionsCardList>Factoring</SolutionsCardList>
              <SolutionsCardList>Business Loan</SolutionsCardList>
            </SolutionsCardListContainer>
            <SolutionsCardLinkContainer>
              <Link to={getStartedUrl} target="_blank">
                Get Started <img src={SolutionCardArrow} />
              </Link>
            </SolutionsCardLinkContainer>
          </SolutionsCardContainer>

          <SolutionsCardContainer>
            <SolutionsCardIcon>
              <img src={InsuranceIcon} />
            </SolutionsCardIcon>
            <SolutionsCardTitle>Insurance</SolutionsCardTitle>
            <SolutionsCardListContainer>
              <SolutionsCardList>Business insurance</SolutionsCardList>
              <SolutionsCardList>Invoice Insurance</SolutionsCardList>
              <SolutionsCardList>Trade Credit Insurance</SolutionsCardList>
            </SolutionsCardListContainer>
            <SolutionsCardLinkContainer>
              <Link to={getStartedUrl} target="_blank">
                Get Started <img src={SolutionCardArrow} />
              </Link>
            </SolutionsCardLinkContainer>
          </SolutionsCardContainer>

          <SolutionsCardContainer>
            <SolutionsCardIcon>
              <img src={BankingIcon} />
            </SolutionsCardIcon>
            <SolutionsCardTitle>Banking</SolutionsCardTitle>
            <SolutionsCardListContainer>
              <SolutionsCardList>Lending</SolutionsCardList>
              <SolutionsCardList>Due Diligence</SolutionsCardList>
              <SolutionsCardList>Reconciliation</SolutionsCardList>
            </SolutionsCardListContainer>
            <SolutionsCardLinkContainer>
              <Link to={getStartedUrl} target="_blank">
                Get Started <img src={SolutionCardArrow} />
              </Link>
            </SolutionsCardLinkContainer>
          </SolutionsCardContainer>
          <SolutionsCardContainer>
            <SolutionsCardIcon>
              <img src={AnalysisIcon} />
            </SolutionsCardIcon>
            <SolutionsCardTitle>Analysis</SolutionsCardTitle>
            <SolutionsCardListContainer>
              <SolutionsCardList>Analyse companies performance</SolutionsCardList>
              <SolutionsCardList>See trading history</SolutionsCardList>
              <SolutionsCardList>Position For Sale</SolutionsCardList>
            </SolutionsCardListContainer>
            <SolutionsCardLinkContainer>
              <Link to={getStartedUrl} target="_blank">
                Get Started <img src={SolutionCardArrow} />
              </Link>
            </SolutionsCardLinkContainer>
          </SolutionsCardContainer>
        </SolutionsCardWrapper>
      </SolutionsContainer>
    </SolutionsWrapper>
  );
};

export default SolutionsView;
