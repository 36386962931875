import { NavigationItem } from 'utils/interfaces/route/navigation/navigation.interface';

export const navItems: NavigationItem[] = [
  {
    text: 'Home',
    hash: '#homepage'
  },
  {
    text: 'Free Trial',
    hash: '#free-trial'
  },
  {
    text: 'Solutions',
    hash: '#solutions'
  },
  {
    text: 'Integration',
    hash: '#integration'
  },
  {
    text: 'How it Works',
    hash: '#how-it-works'
  },
  {
    text: 'Contact Us',
    hash: '#contactUs'
  }
];
