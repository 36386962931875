/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-console
import { FC } from 'react';
import {
  IntegrationBlock40,
  IntegrationBlock60,
  IntegrationBlockCaptionRegular,
  IntegrationContainer,
  IntegrationWrapper,
  IntegrationBlockCaptionLarge,
  IntegrationBlockDescription,
  IntegrationBlockButtonContainer,
  IntegrationBlockButtonText
} from './styled';
import { PrimaryButton } from 'components/common/buttons';
import IntegrationImage from 'assets/images/integration-image.png';
import { getStartedLink } from 'lib/constants/getStartedLink';

const IntegrationView: FC = () => {
  return (
    <IntegrationWrapper id="integration">
      <IntegrationContainer>
        <IntegrationBlock60>
          <IntegrationBlockCaptionRegular>CONNECT</IntegrationBlockCaptionRegular>
          <IntegrationBlockCaptionLarge>Our Integrations</IntegrationBlockCaptionLarge>
          <IntegrationBlockDescription>
            New integrations are being added all the time
          </IntegrationBlockDescription>
          <IntegrationBlockButtonContainer>
            <PrimaryButton clickHandler={() => getStartedLink()}>
              <IntegrationBlockButtonText>Get Started</IntegrationBlockButtonText>
            </PrimaryButton>
          </IntegrationBlockButtonContainer>
        </IntegrationBlock60>
        <IntegrationBlock40>
          <img src={IntegrationImage} />
        </IntegrationBlock40>
      </IntegrationContainer>
    </IntegrationWrapper>
  );
};

export default IntegrationView;
