import { device, themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { TextInputProps } from './TextInput';
import TextField from '@mui/material/TextField';

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextInputStyled = styled(TextField)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  
  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${themeColors.body};
      -webkit-box-shadow: 0 0 0px 1000px ${themeColors.white} inset;
      transition: background-color 5000s ease-in-out 0s;
      outline: none;
    }
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :disabled {
    background-color: ${themeColors.lightGrey};
    color: ${themeColors.grey} !important;
  }

  .MuiInputBase-multiline  {
    padding: 0!important;
    min-height: 100px;
  }

  .MuiOutlinedInput-root {
    ${typography.bodyRegular};
    border-color: ${themeColors.lightGrey};
  }

  .MuiOutlinedInput-input {
    border-radius: 6px!important;
    font-size: ${fontSizes.small};
    padding: 25px;
    color: ${themeColors.mediumGrey};
    background-color: ${themeColors.white};

    &.Mui-disabled {
      background-color: ${themeColors.lightGrey};
      color: ${themeColors.grey} !important;
      -webkit-text-fill-color: ${themeColors.grey} !important;
    }

    :focus-visible, :focus, :hover, :active {
      outline: none;
    }

    @media ${device.mobileOnly} {
      padding: 10px;
    }
  }
`;

export const TextInputLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  ${typography.captionMedium};
  color: ${themeColors.body};
  margin-bottom: 20px;
`;

export const TextInputMessage = styled.span<TextInputProps>`
  color: ${({ errorMessage }) => errorMessage && themeColors.error};
  font-size: ${fontSizes.xsmall};
`;
