import { device } from 'assets/theme/style';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
    position: fixed;
    z-index: 999999;
    width: 100%;

    @media ${device.mobileOnly} {
      width: 100vw;
  }
`;

export const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 100px;
`;

export const AppLoaderContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
