/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-console
import { FC } from 'react';
import {
  HowItWorksContainer,
  HowItWorksContentBg,
  HowItWorksContentContainer,
  HowItWorksHeader,
  HowItWorksHeaderCaption,
  HowItWorksHeaderDescription,
  HowItWorksHeaderTitle,
  HowItWorksStep1Container,
  HowItWorksStep1Wrapper,
  HowItWorksStep2Container,
  HowItWorksStep2Wrapper,
  HowItWorksStep3Container,
  HowItWorksStep3Wrapper,
  HowItWorksStep4Container,
  HowItWorksStep4Wrapper,
  HowItWorksStepIcon,
  HowItWorksStepText,
  HowItWorksStepTitle,
  HowItWorksSteps1IconWrapper,
  HowItWorksSteps2IconWrapper,
  HowItWorksSteps3IconWrapper,
  HowItWorksSteps4IconWrapper,
  HowItWorksWrapper
} from './styled';
import IconStep1 from 'assets/images/work-step1.png';
import IconStep2 from 'assets/images/work-step2.png';
import IconStep3 from 'assets/images/work-step3.png';
import IconStep4 from 'assets/images/work-step4.png';

const HowItWorksView: FC = () => {
  return (
    <HowItWorksWrapper id="how-it-works">
      <HowItWorksContainer>
        <HowItWorksHeader>
          <HowItWorksHeaderTitle>WORKS</HowItWorksHeaderTitle>
          <HowItWorksHeaderCaption>How it Works</HowItWorksHeaderCaption>
          <HowItWorksHeaderDescription>
            New integrations are being added all the time
          </HowItWorksHeaderDescription>
        </HowItWorksHeader>
        <HowItWorksContentContainer>
          {/* <HowItWorksContentBg src={ImageBackground} /> */}
          <HowItWorksStep1Wrapper>
            <HowItWorksStep1Container>
              <HowItWorksStepTitle>STEP 01</HowItWorksStepTitle>
              <HowItWorksStepText>Sign up to G2F Connect</HowItWorksStepText>
            </HowItWorksStep1Container>
            <HowItWorksSteps1IconWrapper>
              <HowItWorksStepIcon src={IconStep1} />
            </HowItWorksSteps1IconWrapper>
          </HowItWorksStep1Wrapper>

          <HowItWorksStep2Wrapper>
            <HowItWorksStep2Container>
              <HowItWorksStepTitle>STEP 02</HowItWorksStepTitle>
              <HowItWorksStepText>
                Invite your customers to connect, simply using their email address
              </HowItWorksStepText>
            </HowItWorksStep2Container>
            <HowItWorksSteps2IconWrapper>
              <HowItWorksStepIcon src={IconStep2} />
            </HowItWorksSteps2IconWrapper>
          </HowItWorksStep2Wrapper>

          <HowItWorksStep3Wrapper>
            <HowItWorksStep3Container>
              <HowItWorksStepTitle>STEP 03</HowItWorksStepTitle>
              <HowItWorksStepText>
                G2F send’s the email and guides them through the connection process
              </HowItWorksStepText>
            </HowItWorksStep3Container>
            <HowItWorksSteps3IconWrapper>
              <HowItWorksStepIcon src={IconStep3} />
            </HowItWorksSteps3IconWrapper>
          </HowItWorksStep3Wrapper>

          <HowItWorksStep4Wrapper>
            <HowItWorksStep4Container>
              <HowItWorksStepTitle>STEP 04</HowItWorksStepTitle>
              <HowItWorksStepText>
                You’re done! Access the data via our Portal or our Simple API
              </HowItWorksStepText>
            </HowItWorksStep4Container>
            <HowItWorksSteps4IconWrapper>
              <HowItWorksStepIcon src={IconStep4} />
            </HowItWorksSteps4IconWrapper>
          </HowItWorksStep4Wrapper>
        </HowItWorksContentContainer>
      </HowItWorksContainer>
    </HowItWorksWrapper>
  );
};

export default HowItWorksView;
