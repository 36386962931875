export const themeColors = {
    primary: '#6D5FA6',
    secondary: '#393939',
    body: '#393939',
    black: '#000000',
    lightBlack: '#222222',
    white: '#ffffff',
    whiteSmoke: '#F5F5F5',
    grey: '#959595',
    mediumGrey: '#B7B7B7',
    lightGrey: '#e2e2e2',
    blue: '#38B5FC',
    yellow: '#FEC70B',
    green: '#4AB777',
    mediumGreen: "#4AB773",
    success: '#3CC60A',
    error: '#E4333E',
    warning: '#F29D39',
};

export const size = {
    desktopS: '768px',
    desktopM: '1024px',
    desktopL: '1366px',
    desktop: '1440px'
};

export const device = {
    mobileOnly: `(max-device-width: ${size.desktopS})`,
    tabletPortrait: `(min-device-width: ${size.desktopS}) and (max-device-width : ${size.desktopM}) and (orientation : portrait)`,
    tabletLandscape: `(min-device-width: ${size.desktopS}) and (max-device-width : ${size.desktopM}) and (orientation : landscape)`,
    desktop1024X768: `(min-width: ${size.desktopS}) and (max-width: ${size.desktopM})`,
    maxDesktopS: `(max-width: ${size.desktopS})`,
    maxDesktopM: `(max-width: ${size.desktopM})`,
    minDesktop: `(min-width: ${size.desktop})`,
    minDesktopS: `(min-width: ${size.desktopS})`,
    minDesktopL: `(min-width: ${size.desktopL})`,
    minDesktopM: `(min-width: ${size.desktopM})`
};

export const formShadow: string = `0px 4px 60px rgba(0, 0, 0, 0.06)`;
export const cardShadow: string = `32px 32px 48px rgba(5, 14, 53, 0.11)`;