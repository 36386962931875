/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-console
import { FC } from 'react';
import {
  TermsAndConditionsContainer,
  TermsAndConditionsContentContainer,
  TermsAndConditionsHeader,
  TermsAndConditionsWrapper
} from './styled';
import G2FLogo from 'assets/icons/G2FLogo';
import { termsAndConditionsHtml } from './dataFile';

const TermsAndConditions: FC = () => {
  return (
    <TermsAndConditionsWrapper>
      <TermsAndConditionsContainer>
        <TermsAndConditionsHeader>
          <G2FLogo />
        </TermsAndConditionsHeader>
        <TermsAndConditionsContentContainer
          dangerouslySetInnerHTML={{ __html: termsAndConditionsHtml }}
        />
      </TermsAndConditionsContainer>
    </TermsAndConditionsWrapper>
  );
};

export default TermsAndConditions;
