/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import {
  FreeTrialBlock40,
  FreeTrialBlock60,
  FreeTrialBlockCaptionRegular,
  FreeTrialContainer,
  FreeTrialWrapper,
  FreeTrialBlockCaptionLarge,
  FreeTrialBlockDescription,
  FreeTrialBlockButtonContainer,
  FreeTrialBlockButtonText
} from './styled';
import { PrimaryButton } from 'components/common/buttons';
import FreeTrialImage from 'assets/images/freeTrial-image.png';
import { getStartedLink } from 'lib/constants/getStartedLink';

const FreeTrial: FC = () => {
  return (
    <FreeTrialWrapper id="free-trial">
      <FreeTrialContainer>
        <FreeTrialBlock60>
          <img src={FreeTrialImage} />
        </FreeTrialBlock60>
        <FreeTrialBlock40>
          <FreeTrialBlockCaptionRegular>OUR OFFER</FreeTrialBlockCaptionRegular>
          <FreeTrialBlockCaptionLarge>
            Try it Out! We Offer a 30 Day Free Trial
          </FreeTrialBlockCaptionLarge>
          <FreeTrialBlockDescription>
            See how G2F can help you access the data you need. Price starts at £25 per month per
            active customer. Our 30 day free trial lets you connect up to 5 customers for free. For
            volume pricing, please contact us
          </FreeTrialBlockDescription>
          <FreeTrialBlockButtonContainer>
            <PrimaryButton clickHandler={() => getStartedLink()}>
              <FreeTrialBlockButtonText>Get Started</FreeTrialBlockButtonText>
            </PrimaryButton>
          </FreeTrialBlockButtonContainer>
        </FreeTrialBlock40>
      </FreeTrialContainer>
    </FreeTrialWrapper>
  );
};

export default FreeTrial;
