import { FC, useState } from 'react';
import {
  NavBarWrapper,
  NavBarContainer,
  NavBarLogo,
  NavBarItemsContainer,
  NavBarItems,
  NavBarItem,
  NavBarButtonText,
  NavBarButtonContainer
} from './styled';
import G2FLogo from 'assets/icons/G2FLogo';
import SecondaryButton from 'components/common/buttons/SecondaryButton';
import { themeColors } from 'assets/theme/style';
import { navItems } from 'lib/navigationData';
import { HashLink } from 'react-router-hash-link';
import MobileNav from './mobileNav';
import { Link } from 'react-router-dom';

const Navbar: FC = () => {
  const [activeMenu, setActiveMenu] = useState<string>('#home');

  const scrollWidthOffset: (el: any) => void = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <NavBarWrapper>
      <NavBarContainer>
        <NavBarLogo>
          <G2FLogo />
        </NavBarLogo>
        <MobileNav />
        <NavBarItemsContainer>
          <NavBarItems>
            {navItems.map((item, index) => (
              <NavBarItem
                key={`${item.text}-${index}`}
                onClick={() => {
                  setActiveMenu(item.hash || '');
                }}
              >
                <HashLink
                  smooth
                  to={`${item.hash}`}
                  className={`${activeMenu === item.hash ? 'selected' : ''}`}
                  scroll={(el) => scrollWidthOffset(el)}
                >
                  {item.text}
                </HashLink>
              </NavBarItem>
            ))}
          </NavBarItems>
          <NavBarButtonContainer>
            <Link to={window.spaSettings.partnerPortalDomain}>
              <SecondaryButton
                backgroundColor={themeColors.lightBlack}
                textColor={themeColors.white}
              >
                <NavBarButtonText>Login</NavBarButtonText>
              </SecondaryButton>
            </Link>
          </NavBarButtonContainer>
        </NavBarItemsContainer>
      </NavBarContainer>
    </NavBarWrapper>
  );
};

export default Navbar;
