import React, { FC } from 'react';
import { LayoutContainer, LayoutViewsContainer } from './styled';

export interface LayoutProps {
    children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
    return (
        <LayoutContainer>
            <LayoutViewsContainer>{children}</LayoutViewsContainer>
        </LayoutContainer>
    );
};

export default Layout;
