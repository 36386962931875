/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-console
import { FC, useState } from 'react';
import {
  ContactUsBlock30,
  ContactUsBlock70,
  ContactUsBlockContainer,
  ContactUsFormButtonText,
  ContactUsFormButtonWrapper,
  ContactUsFormCaption,
  ContactUsFormContainer,
  ContactUsFormRow,
  ContactUsFormSubmitMessage,
  ContactUsHeader,
  ContactUsHeaderCaption,
  ContactUsHeaderTitle,
  ContactUsWrapper,
  FormStyled
} from './styled';
import ContactUsImage from 'assets/images/contactUs-image.png';
import TextInput from 'components/forms/inputs/TextInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/common/buttons';

const FORMSPARK_ACTION_URL = 'https://submit-form.com/9l6NZytR';
export interface IContactForm {
  fullName: string;
  company: string;
  phoneNumber: string;
  emailAddress: string;
  message: string;
}

const ContactUsView: FC = () => {
  const [contactFormData, setContactFormData] = useState<IContactForm>({
    fullName: '',
    company: '',
    phoneNumber: '',
    emailAddress: '',
    message: ''
  });
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IContactForm>({ mode: 'onChange', reValidateMode: 'onChange' });

  const updateValue: (key: string, value: string | boolean) => void = (key, value) =>
    setContactFormData({ ...contactFormData, [key]: value });

  const onSubmit: SubmitHandler<IContactForm> = async () => {
    const { fullName, company, phoneNumber, emailAddress, message } = contactFormData;
    await fetch(FORMSPARK_ACTION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        'Full Name': fullName,
        Company: company,
        'Phone Number': phoneNumber,
        'Email Address': emailAddress,
        Message: message
      })
    });
    setContactFormData({
      fullName: '',
      company: '',
      phoneNumber: '',
      emailAddress: '',
      message: ''
    });
    setFormSubmitted(true);
  };

  return (
    <ContactUsWrapper id="contactUs">
      <ContactUsHeader>
        <ContactUsHeaderTitle>CONTACT US</ContactUsHeaderTitle>
        <ContactUsHeaderCaption>Want To Know How We Can Help Your Business?</ContactUsHeaderCaption>
      </ContactUsHeader>
      <ContactUsBlockContainer>
        <ContactUsBlock30>
          <img src={ContactUsImage} />
        </ContactUsBlock30>
        <ContactUsBlock70>
          <ContactUsFormContainer>
            <ContactUsFormCaption>Get In Touch!</ContactUsFormCaption>

            <FormStyled
              id="contact-form"
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(onSubmit)();
              }}
            >
              <TextInput
                label="Full Name"
                placeholder="Enter Full Name"
                register={register('fullName', { required: true })}
                changeHandler={(event) => updateValue('fullName', event.target.value)}
                defaultValue={contactFormData.fullName}
                errorMessage={
                  !!Object.keys(errors).length
                    ? (contactFormData.fullName === '' || errors.fullName) &&
                      'Full Name is a required field'
                    : ''
                }
              />
              <ContactUsFormRow>
                <TextInput
                  label="Company"
                  changeHandler={(event) => updateValue('company', event.target.value)}
                  placeholder="Enter Company"
                  defaultValue={contactFormData.company}
                />
                <TextInput
                  label="Phone Number"
                  changeHandler={(event) => updateValue('phoneNumber', event.target.value)}
                  placeholder="Enter Phone Number"
                  defaultValue={contactFormData.phoneNumber}
                />
              </ContactUsFormRow>
              <TextInput
                label="Email Address"
                placeholder="Enter Email Address"
                changeHandler={(event) => updateValue('emailAddress', event.target.value)}
                defaultValue={contactFormData.emailAddress}
                register={register('emailAddress', { required: true })}
                errorMessage={
                  !!Object.keys(errors).length
                    ? (contactFormData.emailAddress === '' || errors.emailAddress) &&
                      'Email Address is a required field'
                    : ''
                }
              />
              <TextInput
                multiline
                placeholder="Write Message"
                defaultValue={contactFormData.message}
                changeHandler={(event) => updateValue('message', event.target.value)}
                register={register('message', { required: true })}
                errorMessage={
                  !!Object.keys(errors).length
                    ? (contactFormData.message === '' || errors.message) &&
                      'Message is a required field'
                    : ''
                }
              />
              {formSubmitted && (
                <ContactUsFormSubmitMessage>
                  Thank you for contacting us.
                </ContactUsFormSubmitMessage>
              )}
              <ContactUsFormButtonWrapper>
                <PrimaryButton type="submit" borderRight>
                  <ContactUsFormButtonText>Submit</ContactUsFormButtonText>
                </PrimaryButton>
              </ContactUsFormButtonWrapper>
            </FormStyled>
          </ContactUsFormContainer>
        </ContactUsBlock70>
      </ContactUsBlockContainer>
    </ContactUsWrapper>
  );
};

export default ContactUsView;
