import { device, size, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const SolutionsWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${themeColors.white};

  @media ${device.mobileOnly} {
    padding-bottom: 50px;
  }
  @media only screen and ${device.tabletPortrait} {
    padding-bottom: 50px;
  }
`;

export const SolutionsContainer = styled.div`
  display:flex;
  width: 100%;
  padding: 0 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  max-width: ${size.desktop};
  margin: 0 auto;

  @media ${device.mobileOnly} {
    padding: 0 20px;
    flex-direction: column;
    height: 100%;
  }
  @media only screen and ${device.tabletLandscape} {
    padding: 0 40px;
    padding-bottom: 100px;
  }
  @media only screen and ${device.tabletPortrait} {
    padding: 0 40px;
    padding-bottom: 50px;
  }
  @media only screen and ${device.desktop1024X768} {
    padding: 0 40px;
    padding-bottom: 100px;
  }
`;

export const SolutionsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 56px;

  @media ${device.mobileOnly} {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 56px;
  }
  @media only screen and ${device.tabletLandscape} {
    width: 60%;
  }
  @media only screen and ${device.tabletPortrait} {
    width: 80%;
    padding-top: 50px;
    padding-bottom: 56px;
    gap: 15px;
  }
  @media only screen and ${device.desktop1024X768} {
    width: 60%;
  }
`;

export const SolutionsHeaderTitle = styled.h2`
  margin: 0;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  color: ${themeColors.green};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.small};
  }
`;

export const SolutionsHeaderCaption = styled.h1`
  margin: 0;
  font-size: ${fontSizes.xLarge};
  font-weight: ${fontWeights.bold};
  color: ${themeColors.body};
  line-height: 67px;

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media only screen and ${device.tabletLandscape} {
    line-height: 52px;
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media only screen and ${device.desktop1024X768} {
    line-height: 52px;
  }
`;

export const SolutionsDividerContainer = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
`;

export const SolutionsDividerLeft = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  border: 1px solid;
  border-image: linear-gradient(90deg, ${themeColors.blue},rgb(199 222 236),${themeColors.whiteSmoke}) 1;
  position: relative;
`;

export const SolutionsDividerRight = styled.div`
  display: flex;
  position: relative;
  height: 1px;
  width: 100%;
  border: 1px solid;
  border-image: linear-gradient(90deg, ${themeColors.whiteSmoke},rgb(199 222 236),${themeColors.blue}) 1;
`;

export const SolutionsDividerMiddle = styled.div`
  display: flex;
  position: relative;
  height: 1px;
  width: 10%;
`;

export const SolutionsEllipseIcon = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${themeColors.yellow};
  position: absolute;
  top: -10px;
`;

export const SolutionsEllipseMiddleIcon = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${themeColors.yellow};
  position: absolute;
  top: -10px;
  left: 20px;

  @media ${device.mobileOnly} {
    left: -2px;
  }
  @media only screen and ${device.tabletLandscape} {
    left: 12px;
  }
  @media only screen and ${device.tabletPortrait} {
    left: 4px;
  }
  @media only screen and ${device.desktop1024X768} {
    left: 12px;
  }
`;

export const SolutionsCardWrapper = styled.div`
  display: flex;
  flex: 25vw 25vw 25vw 25vw;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding-top: 120px;

  @media ${device.mobileOnly} {
    gap: 25px;
    flex-wrap: wrap;
    padding-top: 30px;
    justify-content: center;
  }

  @media only screen and ${device.tabletPortrait} {
    flex-wrap: wrap;
  }
`;

export const SolutionsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 305px;
  height: 294px;
  padding: 20px;
  background: ${themeColors.white};
  box-shadow: 32px 32px 48px rgba(5, 14, 53, 0.11);
  border-radius: 10px;
  gap: 20px;

  @media ${device.mobileOnly} {
    width: 90%;
    height: auto;
    padding: 20px;
    flex-direction: column;
    height: 100%;
    gap: 20px;
  }
  @media only screen and ${device.tabletPortrait} {
    width: 290px;
    height: auto;
  }
`;

export const SolutionsCardTitle = styled.h4`
  margin: 0;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
`;

export const SolutionsCardListContainer = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 0 0 20px;
`;

export const SolutionsCardList = styled.li`
  margin: 0;
  font-size: ${fontSizes.xsmall};
  color: ${themeColors.mediumGrey};
`;

export const SolutionsCardLinkContainer = styled.div`

   a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${themeColors.blue};
    text-decoration: none;
   }
`;

export const SolutionsCardIcon = styled.div``;


