import { themeColors, device, size } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const NavBarWrapper = styled.nav`
  position: relative;
  width: 100%;
  height: 100px;
  background-color: ${themeColors.white};
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
`;

export const NavBarContainer = styled.div`
  max-width: ${size.desktop};
  margin: 0 auto;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media ${device.mobileOnly} {
    padding: 0 20px;
  } 
  @media only screen and ${device.tabletLandscape} {
      padding: 0 40px;
    }
  @media only screen and ${device.tabletPortrait} {
    padding: 0 40px;
  }
  @media only screen and ${device.desktop1024X768} {
    padding: 0 40px;
  }
`;

export const NavBarLogo = styled.div`

  @media only screen and ${device.tabletLandscape} {
    svg {
      width: 140px;
    }
  }
  @media only screen and ${device.tabletPortrait} {
    svg {
      width: 140px;
    }
  }
  @media only screen and ${device.desktop1024X768} {
    svg {
      width: 140px;
    }
  }
`;

export const NavBarItemsContainer = styled.div`
  display: flex;
  gap: 24px;

  @media ${device.mobileOnly} {
    display: none;
  }

  @media only screen and ${device.tabletLandscape} {
    gap: 18px;
  }
  @media only screen and ${device.tabletPortrait} {
    gap: 18px;
  }
  @media only screen and ${device.desktop1024X768} {
    gap: 18px;
  }
`;

export const NavBarItems = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style-type: none;

  a {
      font-size: ${fontSizes.regular};
      font-weight: 400;
      color: ${themeColors.grey};
      text-decoration: none;

      @media only screen and ${device.tabletLandscape} {
        font-size: ${fontSizes.small};
      }
      @media only screen and ${device.tabletPortrait} {
        font-size: ${fontSizes.small};
      }
      @media only screen and ${device.desktop1024X768} {
        font-size: ${fontSizes.small};
      }
    }

    a:hover, .selected{
      color: ${themeColors.green};
    }  
`;

export const NavBarItem = styled.li`
  &:not(:last-child) {
    margin-right: 40px;
  }

    @media only screen and ${device.tabletLandscape} {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }
    @media only screen and ${device.tabletPortrait} {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    @media only screen and ${device.desktop1024X768} {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
`;

export const NavBarButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  a {
    text-decoration: none;
  }
`;

export const NavBarButtonText = styled.p`
  margin: 0;
  ${typography.bodyMediumBold};

    @media only screen and ${device.tabletLandscape} {
      font-size: ${fontSizes.small};
    }

    @media only screen and ${device.tabletPortrait} {
      font-size: ${fontSizes.small};
    }

    @media only screen and ${device.desktop1024X768} {
      font-size: ${fontSizes.small};
    }
`;
