import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const TermsAndConditionsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: -70px;
  margin-bottom: 100px;
`;

export const TermsAndConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 940px;
  width: 100%;
  padding: 20px 30px;
  border: none!important;
  background-color: ${themeColors.white};
  box-shadow: 0px 4px 60px rgb(0 0 0 / 6%);
  border-radius: 32px;
  margin: 0 auto;
`;

export const TermsAndConditionsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TermsAndConditionsContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

