import { device, size, themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
  width: 100%;
  background-color: ${themeColors.lightBlack};
`;

export const FooterContainer = styled.div`
  max-width: ${size.desktop};
  margin: 0 auto;
  padding: 45px 80px;

  @media ${device.mobileOnly} {
      padding: 45px 20px;
  }
`;

export const FooterG2FLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  @media ${device.mobileOnly} {
    padding-bottom: 10px;

    img {
      max-width: 140px;
    }
  }
`;

export const FooterNavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

    @media ${device.mobileOnly} {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
`;

export const FooterNavigationContainer = styled.ul`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  padding: 0;

  a {
      font-size: ${fontSizes.regular};
      font-weight: 400;
      color: ${themeColors.white};
      text-decoration: none;
    }

    a:hover, a.active{
      text-decoration: underline;
    } 

    @media ${device.mobileOnly} {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
`;

export const FooterNavigationItem = styled.li`
  margin-right: 40px;

    @media ${device.mobileOnly} {
      margin-right: 0;
    }
    @media only screen and ${device.tabletPortrait} {
      margin-right: 20px;
    }
`;

export const FooterNavigationSocialMediaIcons = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;

  img {
    cursor: pointer;
  }
`;

export const FooterBottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${themeColors.white};

  a {
      font-size: ${fontSizes.regular};
      font-weight: 400;
      color: ${themeColors.white};
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }  

    @media ${device.mobileOnly} {
      justify-content: center;
      gap: 10px;
      flex-direction: column;

      a {
        font-size: ${fontSizes.small};
      }
    }
    @media only screen and ${device.tabletPortrait} {
      justify-content: center;
      gap: 10px;
      flex-direction: column;

      a {
        font-size: ${fontSizes.small};
      }
    }
`;

export const FooterBottomText = styled.p`
  font-size: ${fontSizes.regular};
  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
`;

export const FooterBottomCopyright = styled.p`
  margin: 0;
  font-size: ${fontSizes.xsmall};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.xxsmall};
  }
  @media only screen and ${device.tabletPortrait} {
    font-size: ${fontSizes.xxsmall};
  }
`;

