import { device, size, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights } from 'assets/theme/typography';
import styled from 'styled-components';
import ImageBackground from 'assets/images/works-bg.png';

export const HowItWorksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  @media ${device.mobileOnly} {
    height: auto;
    padding-bottom: 50px;
  }
  @media ${device.minDesktopS} {
    min-height: 700px;
  }
`;

export const HowItWorksContainer = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 0 80px;
  justify-content: center;
  align-items: center;
  max-width: ${size.desktop};
  margin: 0 auto;

  @media ${device.mobileOnly} {
    padding: 0 20px;
    height: 100%;
  }
  @media ${device.minDesktopS} {
    padding: 0 40px;
  }
  @media ${device.minDesktopM} {
    padding: 0 40px;
  }
`;

export const HowItWorksHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-start;
  padding-top: 100px;
  padding-bottom: 56px;

  @media ${device.mobileOnly} {
    padding: 50px 0px 20px 0px;
  }
  @media ${device.minDesktopS} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media ${device.minDesktopM} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const HowItWorksHeaderTitle = styled.h2`
  margin: 0;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  color: ${themeColors.green};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
  @media ${device.minDesktopS} {
    font-size: ${fontSizes.small};
    justify-content: center;
    gap: 15px;
  }
  @media ${device.minDesktopM} {
    justify-content: center;
    gap: 25px;
  }
`;

export const HowItWorksHeaderCaption = styled.h1`
  margin: 0;
  font-size: ${fontSizes.xLarge};
  font-weight: ${fontWeights.bold};
  color: ${themeColors.body};
  line-height: 67px;

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media ${device.minDesktopS} {
    font-size: ${fontSizes.large};
    line-height: 42px;
  }
  @media ${device.minDesktopM} {
    font-size: ${fontSizes.xLarge};
    line-height: 52px;
  }
`;

export const HowItWorksHeaderDescription = styled.div`
  margin: 0;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  color: ${themeColors.grey};
  line-height: 44px;

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
    line-height: 32px;
  }

  @media ${device.minDesktopS} {
    font-size: ${fontSizes.small};
    line-height: 32px;
  }
  @media ${device.minDesktopM} {
    font-size: ${fontSizes.small};
    line-height: 32px;
  }
`;

export const HowItWorksContentContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 500px;
  position: relative;
  background-image: url(${ImageBackground});
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;

  @media ${device.mobileOnly} {
    flex-direction: column;
    gap: 20px;
    background-image: none;
  }
`;

export const HowItWorksContentBg = styled.img`
  width: 100%;
  position: absolute;
  top:0;
  left:0;

  @media ${device.mobileOnly} {
    display: none;
  }
`;

export const HowItWorksStepTitle = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: ${fontWeights.medium};

  @media ${device.mobileOnly} {
    font-size: ${fontSizes.regular};
    line-height: 32px;
  }
  @media ${device.minDesktopS} {
    font-size: ${fontSizes.regular};
    line-height: 32px;
  }
  @media ${device.minDesktopM} {
    font-size: ${fontSizes.medium};
    line-height: 32px;
  }
`;

export const HowItWorksStepText = styled.p`
  padding: 0;
  margin: 0;
  color: ${themeColors.grey};
  line-height: 35px;
`;

export const HowItWorksStep1Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: absolute;
  top:0;
  left:0;

  @media ${device.mobileOnly} {
    position: relative;
  }
  
  @media ${device.minDesktopM} {
    top: -10px;
  }
`;

export const HowItWorksStep1Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
`;

export const HowItWorksStep2Wrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
  position: absolute;
  bottom: -5vh;
  left: 24vw;

  @media ${device.mobileOnly} {
    position: relative;
    top: 0;
    left: 0;
  }
  @media ${device.minDesktopM} {
    bottom: 5vh;
    left: 20vw;
  }
  @media ${device.minDesktopL} {
    bottom: -3vh;
    left: 20vw;
  }
  @media ${device.minDesktop} {
    bottom: -5vh;
    left: 20vw;
  }
`;

export const HowItWorksStep2Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
`;

export const HowItWorksStep3Wrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
  position: absolute;
  top: 0;
  left: 55vw;

  @media ${device.mobileOnly} {
    position: relative;
    top: 0;
    left: 0;
  }
  @media ${device.minDesktopL} {
    top: 5vh;
    left: 55vw;
  }
  @media ${device.minDesktop} {
    top: -5vh;
    left: 55vw;
  }
`;


export const HowItWorksStep3Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
`;


export const HowItWorksStep4Wrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: flex-end;

  @media ${device.mobileOnly} {
    position: relative;
    top: 0;
    left: 0;
    align-items: flex-start;
  }
  @media ${device.minDesktopM} {
    bottom: 0;
    right: 3vw;
    text-align: right;
    left: auto;
  }
  @media ${device.minDesktopL} {
    bottom: -10vh;
    right: 3vw;
    text-align: right;
    left: auto;
  }
  @media ${device.minDesktop} {
    bottom: -10vh;
    right: 3vw;
    text-align: right;
    left: auto;
  }
`;

export const HowItWorksStep4Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
  align-items: flex-end;

  @media ${device.mobileOnly} {
    align-items: flex-start;
  }
`;

export const HowItWorksSteps1IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 126px;
  padding: 30px;
  background: ${themeColors.white};
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  @media ${device.mobileOnly} {
    display: none;
  }
  @media ${device.minDesktopM} {
    max-width: 86px;
    padding: 20px;
  }
`;

export const HowItWorksSteps2IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 126px;
  padding: 30px;
  background: ${themeColors.white};
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  @media ${device.mobileOnly} {
    display: none;
  }
  @media ${device.minDesktopM} {
    max-width: 86px;
    padding: 20px;
  }
`;

export const HowItWorksSteps3IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 126px;
  padding: 30px;
  background: ${themeColors.white};
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  @media ${device.mobileOnly} {
    display: none;
  }
  @media ${device.minDesktopM} {
    max-width: 86px;
    padding: 20px;
  }
`;

export const HowItWorksSteps4IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 126px;
  padding: 30px;
  background: ${themeColors.white};
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  align-items: flex-end;

  @media ${device.mobileOnly} {
    display: none;
  }
  @media ${device.minDesktopM} {
    max-width: 86px;
    padding: 20px;
  }
`;

export const HowItWorksStepIcon = styled.img`
  width: 60px;
  height: 60px;

  @media ${device.minDesktopM} {
    width: 40px;
    height: 40px;
  }
`;